import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Layout',
      component: () => import('@/views/Layout'),
      redirect: '/articleList',
      children: [
        {
          path: 'articleList',
          name: 'ArticleList',
          component: () => import('@/views/ArticleList'),
        },
        {
          path: 'article/:id',
          name: 'Article',
          component: () => import('@/views/Article'),
        },
      ],
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login'),
    },
    {
      path: '/articleAdmin',
      name: 'ArticleAdmin',
      component: () => import('@/views/ArticleAdmin'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  // 判断是否含有token
  if (token) {
    // 如果有token不能去登录页
    if (to.path === '/login') {
      next('/articleAdmin')
    } else {
      next()
    }
  } else {
    // 未登录不能进管理页
    if (to.path.startsWith('/articleAdmin')) {
      next('/login')
    } else {
      next()
    }
  }
})

export default router
